import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

//import Newsletter from '../containers/Newsletter'

const links = [
	['/', 'Home'],
	['//patreon.com/lahoramachorra', 'Members'],
	['//shop.lahoramachorra.com', 'Merch'],
	//['/blog', 'Blog'],
	//['/faq', 'FAQ'],
	['/contact', 'Contact'],
	['/legal', 'Legal'],
	['/privacy', 'Privacy']
]

const Footer = () => {
	const { config, socialLinks, sponsors } = useSelector((state) => {
		return {
			config: state.api.config,
			socialLinks: state.api.socialLinks,
			sponsors: state.api.sponsors
		}
	})
	
	return <footer className="p-2">
		<div className="section-wrap">
			<div className="grid-g">
				
				<div className="col-12 col-laptop-4 navigation">
					<h5>Navigation</h5>
					<div className="grid-g">
						{links.map(link => <div key={link[0]} className="col-6">
							<Link to={link[0]}>{link[1]}</Link>
						</div>)}
					</div>
				</div>
				
				<div className="col-12 col-laptop-4">
					
					<div className="info">
						<h5 className="lhm-text">LA HORA MACHORRA</h5>
						<div className="description">Un podcast de empoderamiento
		para el hombre moderno.</div>
						<div className="sponsors">
							<h5 className="heading">Sponsored by</h5>
							{sponsors.map(sponsor => <div key={sponsor.brand} className="col-12">
							<img src={sponsor.logoImage} alt={sponsor.brand} />
						</div>)}
						</div>
					</div>
				</div>
				
				<div className="col-12 col-laptop-4 photo">
					<img src={config.footerPic} alt="Team" />
				</div>
				
				<div className="col-12 copyright text-center font-size-8">
					<div>© {(new Date()).getFullYear()} La Hora Machorra</div>
					<div className="social-media">
						{socialLinks.map(link => <a
							key={link.service}
							className={"fab fa-" + link.service}
							target="_blank"
							href={link.url}
							rel="noreferrer"
						/>)}
					</div>
				</div>
				
			</div>
		</div>
	</footer>
}

export default Footer