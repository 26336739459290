import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'timeago.js'

import Layout from '../Layout'

const defaultThumbnail = '/assets/i/default-video-thumbnail.png'

const fetchEpisodes = () => new Promise((resolve, reject) => {
	axios.get(`/videos?populate=*`)
		.then(response => {
			const videoData = response.data && response.data.data
			
			resolve(Object.keys(videoData).map(i => {
				const { videoId, title, episode, thumbnail, publishedAt } = videoData[i].attributes
				const thumbnailUrl = thumbnail.data ? thumbnail.data.attributes.url : null
				
				return {
					videoId,
					title,
					episode,
					thumbnail: thumbnailUrl || defaultThumbnail,
					published: publishedAt
				}
			}))
		})
		.catch(error => reject(error))
})

const Episodes = () => {
	const [videoList, setVideoList] = useState([])
	
	useEffect(() => {
		fetchEpisodes()
			.then(res => setVideoList(res))
			.catch(error => console.log(error))
	}, [])
	
	return <Layout page="videos">
		<section className="video-list">
			<div className="header section-wrap">
				<div className="grid-g">
					<div className="col-12 heading">
						<h3>Episodes</h3>
					</div>
					
					{Object.keys(videoList).map(videoId => 
						<div key={videoList[videoId].videoId} className="col-6 col-tablet-3 video">
							<div className="img-wrap">
								<Link to={'/video/' + videoList[videoId].videoId}>
									<img src={videoList[videoId].thumbnail} alt={videoList[videoId].title}></img>
									<div className="episode">Episode {videoList[videoId].episode}</div>
									<div className="name">{videoList[videoId].title}</div>
									<div className="published">{format(videoList[videoId].published)}</div>
								</Link>
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	</Layout>
}

export default Episodes