import axios from 'axios'
import {
	CONFIG_FETCH_SUCCESS,
	CONFIG_FETCH_FAILURE,
	
	VIDEO_FETCH_SUCCESS,
	VIDEO_FETCH_FAILURE,
	
	SOCIALS_FETCH_SUCCESS,
	SOCIALS_FETCH_FAILURE,
	
	SHOP_FETCH_SUCCESS,
	SHOP_FETCH_FAILURE,
	
	SPONSORS_FETCH_SUCCESS,
	SPONSORS_FETCH_FAILURE
} from '../constants/actionTypes.js'

const API_TOKEN = 'ff693178852af3709f797af7e0f17b8dc48f71b363ec5fe50cff746133963016cccf7565d6dc43ee1821ae733bf19b619ba0dbe93c85655cff078e0f3d6689a705405055a316718d9227e8c08af9365698f2f26adebb97f6e404c0cea28c205e977436ee3689ff57bda3c458f06fa3a809382814c15cff019184c08c30c570e9'

axios.defaults.baseURL = 'https://lahoramachorra-cms.herokuapp.com/api'
axios.defaults.headers.common['Authorization'] = 'bearer ' + API_TOKEN
axios.defaults.headers.common['Accept'] = 'application/json'

const noop = () => ({ type: 'NOOP' })

export const fetchConfigData = () => {
	return dispatch => axios.get('/config?populate=*')
		.then(response => {
			const data = response.data && response.data.data && response.data.data.attributes
			
			return dispatch({
				type: CONFIG_FETCH_SUCCESS,
				payload: data || {}
			})
		})
		.catch(error => dispatch({
			type: CONFIG_FETCH_FAILURE,
			payload: error
		}))
}

export const fetchVideoData = videoId => {
	return (dispatch, getState) => {
		const { videos } = getState().api
		
		return videos[videoId] ? noop() : axios.get(`/videos?filters[videoId][$eq]=${videoId}&populate=*`)
			.then(response => {
				const data = response.data && response.data.data && response.data.data[0] && response.data.data[0].attributes
				
				return data && dispatch({
					type: VIDEO_FETCH_SUCCESS,
					payload: data || {}
				})
			})
			.catch(error => dispatch({
				type: VIDEO_FETCH_FAILURE,
				payload: error
			}))
	}
}

export const fetchSocialLinks = () => {
	return dispatch => axios.get('/social-links')
		.then(response => dispatch({
			type: SOCIALS_FETCH_SUCCESS,
			payload: response.data
		}))
		.catch(error => dispatch({
			type: SOCIALS_FETCH_FAILURE,
			payload: error
		}))
}

export const fetchShopProducts = () => {
	return dispatch => axios.get('/shops?populate=*')
		.then(response => dispatch({
			type: SHOP_FETCH_SUCCESS,
			payload: response.data
		}))
		.catch(error => dispatch({
			type: SHOP_FETCH_FAILURE,
			payload: error
		}))
}

export const fetchSponsors = () => {
	return dispatch => axios.get('/sponsors?populate=*')
		.then(response => dispatch({
			type: SPONSORS_FETCH_SUCCESS,
			payload: response.data
		}))
		.catch(error => dispatch({
			type: SPONSORS_FETCH_FAILURE,
			payload: error
		}))
}