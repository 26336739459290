import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { format } from 'timeago.js'
import { DiscussionEmbed } from 'disqus-react'

import Layout from '../Layout'

import { fetchVideoData } from '../../actions/api'
import VideoWrap from '../../containers/VideoWrap'

const VideoPage = () => {
	const dispatch = useDispatch()
	let { videoId } = useParams()
	const videos = useSelector((state) => state.api.videos)
	const [videoData, setVideoData] = useState({})
	
	useEffect(() => {
		setVideoData({}) // Clear data if user navigates away from page
	}, [])
	
	useEffect(() => {
		dispatch(fetchVideoData(videoId))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videoId])
	
	useEffect(() => {
		if (videos[videoId]) setVideoData(videos[videoId])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos])
	
	return <Layout page="video">
		<section className="video-player">
			<div className="wrap align-center-wrap">
				{videoData.src && <VideoWrap videoId={videoData.videoId} height={640} />}
			</div>
			
			<div className="details section-wrap">
				<div className="episode">Episode {videoData.episode}</div>
				<div className="name">{videoData.title}</div>
				<div className="published">{format(videoData.published)}</div>
			</div>
			
			<div className="comments section-wrap">
				<div className="heading">Comments</div>
				
				<DiscussionEmbed
					shortname="lahoramachorra"
					config={
						{
							url: 'https://lahoramachorra.vercel.app/video/' + videoId,
							identifier: videoId,
							title: videoData.title
						}
					}
				/>
			</div>
		</section>
	</Layout>
}

export default VideoPage