/* eslint-disable react-hooks/exhaustive-deps */
/*
npm i -D video.js@6.13.0 @silvermine/videojs-quality-selector@1.2.5 @silvermine/videojs-chromecast@1.3.3 @silvermine/videojs-airplay@1.1.0 @videojs/themes@1.0.1
*/

import React, { Suspense, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

const LOAD_DELAY = 1 // Makes sure every library loads

let scripts = [
	['/assets/lib/video.min.js', () => typeof window.videojs !== 'undefined'],
	['/assets/lib/silvermine-videojs-quality-selector.min.js', () => typeof window.videojs.getComponent('QualitySelector') !== 'undefined'],
	['/assets/lib/silvermine-videojs-chromecast.min.js', () => typeof window.videojs.getPlugins().chromecast !== 'undefined'],
	['/assets/lib/silvermine-videojs-airplay.min.js', () => typeof window.videojs.getPlugins().airPlay !== 'undefined'],
	'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
]

let styles = [
	'https://unpkg.com/video.js@6.13.0/dist/video-js.css',
	'https://unpkg.com/@videojs/themes@1.0.1/dist/forest/index.css',
	'https://unpkg.com/@silvermine/videojs-quality-selector@1.2.5/dist/css/quality-selector.css',
	'https://unpkg.com/@silvermine/videojs-chromecast@1.3.3/dist/silvermine-videojs-chromecast.css',
	'https://unpkg.com/@silvermine/videojs-airplay@1.1.0/dist/silvermine-videojs-airplay.css'
]

export const Video = props => {
	const videoRef = React.useRef(null)
	const playerRef = React.useRef(null)
	const [libsLoaded, setLibsLoaded] = useState(null)
	
	const { options, onReady, videoId, height } = props
	
	const COOKIE_NAME = 'timeLeftOff_' + (videoId || '')
	const [cookies, setCookie] = useCookies([COOKIE_NAME])
	const timeLeftOff = Number(videoId && cookies[COOKIE_NAME])
	
	const loadScript = (source, conditionCheck) => new Promise((resolve, reject) => {
		const conditionMet = typeof conditionCheck === 'function' ? conditionCheck() : true
		
		if (document.querySelector(`script[src="${source}"]`)) return //setTimeout(lve, conditionMet ? 0 : LOAD_DELAY)
		
		const script = document.createElement('script')
		script.src = source
		script.async = true
		script.onload = () => setTimeout(() => {
			if (conditionMet) return resolve()
			
			const conditionCheckLoop = typeof conditionCheck === 'function' && setInterval(() => {
				if (conditionCheck()) {
					clearInterval(conditionCheckLoop)
					resolve()
				}
			}, LOAD_DELAY)
		}, conditionMet ? 0 : LOAD_DELAY)
		script.onerror = reject
		
		document.body.appendChild(script)
	})
	
	const loadScripts = i => {
		setLibsLoaded(window.videoJsReady)
		
		if (typeof i !== 'number') i = 0
		
		const scriptData = scripts[i]
		
		loadScript(...(typeof scriptData === 'object' ? [scriptData[0], scriptData[1]] : [scriptData]))
			.then(() => {
				i++
				i < scripts.length ? loadScripts(i) : (() => {
					setLibsLoaded(true)
					window.videoJsReady = true
				})()
			})
	}
	
	useEffect(() => {
		loadScripts()
		
		for (var i = 0; i < styles.length; i++) {
			if (!document.querySelector(`link[href="${styles[i]}"]`)) {
				const style = document.createElement('link')
				style.href = styles[i]
				style.rel = 'stylesheet'
				
				document.head.appendChild(style)
			}
		}
	}, [])
	
	useEffect(() => {
		if (!libsLoaded) return
		
		// Make sure Video.js player is only initialized once
		if (!playerRef.current) {
			const videoElement = videoRef.current
			
			const player = playerRef.current = window.videojs(videoElement, {
				...(options || {}),
				//autoplay: true,
				controls: true,
				height: height || 640,
				techOrder: [ 'chromecast', 'html5' ],
				plugins: {
					chromecast: { receiverAppID: '049AEE6A' }
				}
			},
			() => {
				const setTimeCookie = () => {
					const atVideoTimeDisposed = player.currentTime()
					
					if (atVideoTimeDisposed) {
						setCookie(COOKIE_NAME, atVideoTimeDisposed)
					}
				}
				
				let videoInitiated = false
				
				player.on('pause', setTimeCookie)
				player.on('dispose', setTimeCookie)
				
				player.on('loadedmetadata', () => {
					if (timeLeftOff > 0) player.currentTime(timeLeftOff)
				})
				
				player.on('canplaythrough', () => {
					if (!videoInitiated && timeLeftOff > 0) {
						player.currentTime(timeLeftOff)
						videoInitiated = true
					}
				})
				
				player.controlBar.addChild('QualitySelector')
				player.chromecast()
				player.airPlay()
				onReady && onReady(player)
			})
		} else {
			//player.autoplay(options.autoplay)
			//player.src(options.sources)
		}
	}, [libsLoaded, onReady, options, videoRef])
	
	// Dispose the Video.js player when the functional component unmounts
	useEffect(() => {
		const player = playerRef.current
		
		return () => {
			if (player) {
				player.dispose()
				playerRef.current = null
			}
		}
	}, [playerRef])
	
	return (
		<Suspense fallback={<div>LOADING</div>}>
			<div data-vjs-player>
				<video
					ref={videoRef}
					className='video-js vjs-big-play-centered vjs-theme-forest'
				/>
			</div>
		</Suspense>
	)
}

export default Video