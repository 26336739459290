export const USER_FETCH_REQUEST = 'USER_FETCH_REQUEST'
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE'

export const USER_LOGGED_IN = 'USER_LOGGED_IN'

export const PROFILE_FETCH_REQUEST = 'PROFILE_FETCH_REQUEST'
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS'
export const PROFILE_FETCH_FAILURE = 'PROFILE_FETCH_FAILURE'

export const CONFIG_FETCH_REQUEST = 'CONFIG_FETCH_REQUEST'
export const CONFIG_FETCH_SUCCESS = 'CONFIG_FETCH_SUCCESS'
export const CONFIG_FETCH_FAILURE = 'CONFIG_FETCH_FAILURE'

export const VIDEO_FETCH_REQUEST = 'VIDEO_FETCH_REQUEST'
export const VIDEO_FETCH_SUCCESS = 'VIDEO_FETCH_SUCCESS'
export const VIDEO_FETCH_FAILURE = 'VIDEO_FETCH_FAILURE'

export const SOCIALS_FETCH_REQUEST = 'SOCIALS_FETCH_REQUEST'
export const SOCIALS_FETCH_SUCCESS = 'SOCIALS_FETCH_SUCCESS'
export const SOCIALS_FETCH_FAILURE = 'SOCIALS_FETCH_FAILURE'

export const SPONSORS_FETCH_REQUEST = 'SPONSORS_FETCH_REQUEST'
export const SPONSORS_FETCH_SUCCESS = 'SPONSORS_FETCH_SUCCESS'
export const SPONSORS_FETCH_FAILURE = 'SPONSORS_FETCH_FAILURE'

export const SHOP_FETCH_REQUEST = 'SHOP_FETCH_REQUEST'
export const SHOP_FETCH_SUCCESS = 'SHOP_FETCH_SUCCESS'
export const SHOP_FETCH_FAILURE = 'SHOP_FETCH_FAILURE'