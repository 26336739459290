import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Layout from '../Layout'
import VideoWrap from '../../containers/VideoWrap'

import { fetchVideoData, fetchShopProducts } from '../../actions/api'

const Landing = () => {
	const dispatch = useDispatch()
	const { config, shopProducts, videos } = useSelector((state) => {
		return {
			config: state.api.config,
			shopProducts: state.api.shopProducts,
			videos: state.api.videos
		}
	})
	const [videoData, setVideoData] = useState({})
	
	useEffect(() => {
		dispatch(fetchShopProducts())
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	useEffect(() => {
		dispatch(fetchVideoData(config.homeVideoId))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config])
	
	useEffect(() => {
		if (videos[config.homeVideoId]) setVideoData(videos[config.homeVideoId])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos])
	
	return <Layout page="landing" theme="dark" nav={['absolute']}>
		<section className="hero" style={{
			background: `linear-gradient(rgba(0,0,30,.6) 0,rgba(0,0,10,.6) 80%),url(/assets/i/noise.png),url(${config ? config.heroImage : ''}) 50% 33%/cover`
		}} />
		
		<section className="video-player">
			<div className="header section-wrap grid">
				<div className="col-12 heading">
					<div className="grid">
						<div className="col-9">
							<h3>THIS WEEK'S EPISODE</h3>
							<Link to={'/video/' + videoData.videoId}>{videoData.title}</Link>
						</div>
						<div className="col-3 episode">{videoData.episode}</div>
					</div>
				</div>
			</div>
			<div className="wrap align-center-wrap">
				{config.homeVideoId && <VideoWrap videoId={config.homeVideoId} height={640} />}
			</div>
			<div className="grid section-wrap">
				<div className="col-12">
					<Link to="/episodes" className="external-link">View Episodes</Link>
				</div>
			</div>
		</section>
		
		<section className="newsletter">
			
		</section>
		
		<section className="merch">
			<div className="header section-wrap">
				<div className="grid-g">
					<div className="col-12 heading">
						<h3>MERCH</h3>
					</div>
					
					{shopProducts && shopProducts.map(product => <div
						key={product.name}
						className="col-6 col-tablet-3 merch-product"
					>
						<a>
							<div className="img-wrap">
								<img src={product.photo} alt={product.name}></img>
								<div className="overlay" />
							</div>
							<div className="name">{product.name}</div>
							<div className="price">{product.price}</div>
							<span className="coming-soon">Coming Soon</span>
						</a>
					</div>)}
					
					<div className="col-12">
						<a
							className="external-link"
							target="_blank"
							href={config ? config.shopUrl : '#'}
							rel="noreferrer"
						>
							View Collection
						</a>
					</div>
				</div>
			</div>
		</section>
	</Layout>
}
// <a target="_blank" href={product.url} rel="noreferrer">
export default Landing