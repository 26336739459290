import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import AppRoutes from './Routes'

import { fetchConfigData, fetchSocialLinks, fetchSponsors } from '../actions/api'
import { setAuthStatus } from '../actions/user'

const App = () => {
	const dispatch = useDispatch()
	
	useEffect(() => {
		dispatch(fetchConfigData())
		dispatch(fetchSocialLinks())
		dispatch(fetchSponsors())
		dispatch(setAuthStatus(true))
	})
	
	return <AppRoutes />
}

export default App