import { combineReducers } from 'redux'

import { fetchStatus, fetchErrors } from './fetchStatus'

import { api } from './api'
import { user } from './user'

export default combineReducers({
	fetchStatus,
	fetchErrors,
	
	api,
	user
})