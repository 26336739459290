/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

//import { BurgerMenu } from './UI'

const links = [
	['/', 'Home'],
	['/episodes', 'Episodes'],
	['//patreon.com/lahoramachorra', 'Members']
]

const Navigation = props => {
	const { config, socialLinks } = useSelector((state) => {
		return {
			config: state.api.config,
			socialLinks: state.api.socialLinks
		}
	})
	
	return <nav
		className={classNames(props.classNames)}
		role="navigation"
	>
		<div className="placeholder"></div>
		<div className="box p-x-3">
			<Link to="/" className="text-logo float-l">LA HORA MACHORRA</Link>
			<div className="align-center-wrap float-r">
				<ul>
					{links.map(link => {
						const className = classNames('nav-btn', {
							active: props.page === link[0]
						})
						
						return <li key={link[0]} className={className}>
							<Link to={link[0]}>{link[1]}</Link>
						</li>
					})}
				</ul>
				
				<div className="social-media">
					<span className="separator">&bull;</span>
					{socialLinks.map(link => <a
						key={link.service}
						className={"fab fa-" + link.service}
						target="_blank"
						href={link.url}
						rel="noreferrer"
					/>)}
				</div>
				
				<a className="cta" href={config ? config.shopUrl : '#'} target="_blank" rel="noreferrer">MERCH</a>
			</div>
		</div>
	</nav>
}

export default Navigation