import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchVideoData } from '../actions/api'
import Video from './Video'

const VIDEO_QUALITIES = ['1080', '720', '480']

const VideoWrap = props => {
	const dispatch = useDispatch()
	const videos = useSelector(state => state.api.videos)
	const [mounted, setMounted] = useState(null)
	const [videoData, setVideoData] = useState({})
	const playerRef = useRef(null)
	
	useEffect(() => {
		dispatch(fetchVideoData(props.videoId))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])
	
	useEffect(() => {
		setMounted(true)
	}, [mounted])
	
	useEffect(() => {
		if (videos[props.videoId]) setVideoData(videos[props.videoId])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos])
	
	const handlePlayerReady = player => {
		playerRef.current = player
		
		player.src(VIDEO_QUALITIES.map(quality => videoData.src[quality] ? {
			src: videoData.src[quality],
			label: quality + 'p',
			selected: quality === 1080 || null,
			type: 'video/mp4'
		} : null))
	}
	
	return <>
		{mounted && videoData.videoId && <Video
			videoId={videoData.videoId}
			onReady={handlePlayerReady}
		/>}
	</>
}

export default VideoWrap