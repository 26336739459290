import {
	CONFIG_FETCH_SUCCESS,
	VIDEO_FETCH_SUCCESS,
	//VIDEOS_FETCH_SUCCESS,
	SOCIALS_FETCH_SUCCESS,
	SHOP_FETCH_SUCCESS,
	SPONSORS_FETCH_SUCCESS
} from '../constants/actionTypes.js'

export const api = (state = {
	config: {},
	socialLinks: [],
	sponsors: [],
	videos: {}
}, action) => {
	switch(action.type) {
		case CONFIG_FETCH_SUCCESS: {
			return {
				...state,
				config: {
					homeVideoId: action.payload.homeVideoId,
					shopUrl: action.payload.shopUrl,
					footerPic: action.payload.footerPic.data.attributes.url,
					heroImage: action.payload.heroImage.data.attributes.url
				}
			}
		}
		case VIDEO_FETCH_SUCCESS: {
			return {
				...state,
				videos: {
					...state.videos,
					...(!state.videos[action.payload.videoId] ? {
						[action.payload.videoId]: {
							videoId: action.payload.videoId,
							title: action.payload.name,
							episode: action.payload.episode || 1,
							published: action.payload.publishedAt,
							src: {
								1080: action.payload.video.data[0].attributes.url,
							}
						}
					} : null)
				}
			}
		}
		case SOCIALS_FETCH_SUCCESS: {
			return {
				...state,
				socialLinks: action.payload.data.map(
					({ attributes: { service, url } }) => ({ service, url })
				)
			}
		}
		case SHOP_FETCH_SUCCESS: {
			console.log(action)
			return {
				...state,
				shopProducts: action.payload.data.map(
					({ attributes: { name, price, url, photo } }) => ({
						name,
						price,
						url,
						photo: photo.data.attributes.url
					})
				)
			}
		}
		case SPONSORS_FETCH_SUCCESS: {
			return {
				...state,
				sponsors: action.payload.data.map(
					({ attributes: { brand, logoImage } }) => ({
						brand,
						logoImage: logoImage.data.attributes.url
					})
				)
			}
		}
		default: {
			return state
		}
	}
}